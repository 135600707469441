import React from "react"

import { useStaticQuery, graphql } from "gatsby"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import BigHeaderVideo from "../../../components/header/bigHeader/bigHeaderVideo"
import "../../../components/layout/layout.css"
import "../../../components/devicon/devicon.css"
import logo from "../../../../static/logos/logo-kuhmichel.svg"
import Kontakt from "../../../components/kontakt"
import Navigation from '../../../components/projekte/navigation'
import {AnchorLink} from "gatsby-plugin-anchor-links"
import ThumbleisteSwiper from "../../../components/thumbleisteSwiper"
import Liste from "../../../components/liste"
import ListenModule from "../../../components/listenModule"
import myLocalVid from '../../../images/projekte/kuhmichel-website/kuhmichel-website.mp4'
import * as projekteStyles from "../projekte.module.css"
import Specs from "../../../components/specs"


const KuhmichelWebsite = function (props, ref) {

    const data = useStaticQuery(graphql`
        query KuhmichelWebsiteQuery {
            heropic: file(relativePath: { eq: "images/projekte/kuhmichel-website/thumbleiste/kuhmichel-website-05.jpg" }) {
                childImageSharp {
                    gatsbyImageData(          
                      placeholder: BLURRED,
                      layout: FULL_WIDTH,
                      aspectRatio: 1.7
                    )
                  }
            }

            ogimage: file(relativePath: { eq: "images/projekte/kuhmichel-website/thumbleiste/kuhmichel-website-05.jpg" }) {
                childImageSharp {
                    fixed(height: 400) {
                        src
                        height
                        width
                    }
                }
            }

            allFile(filter: {extension: {regex: "/(jpg|png)/"}, relativeDirectory: {eq: "images/projekte/kuhmichel-website/thumbleiste"}} sort: {fields: name}) {
                edges {
                  node {
                    id
                    childImageSharp {
                        picscropped:gatsbyImageData(placeholder: BLURRED, aspectRatio: 1, layout: FULL_WIDTH, formats: [WEBP], transformOptions: {rotate: 0, cropFocus: CENTER})
                        pics:gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH, formats: [WEBP])
                      }
                  }
                }
              }                                     
        }
    `)

    const specsData = [
        { title: "Eckdaten", objects: [logo, "Webseite", "https://www.kuhmichel.com"] },
        { title: "Leistungen", objects: ["konzept", "design", "programmierung"] },
        {
          title: "Toolchain",
          objects: ["sketch", "html", "css", "js", "wordpress"],
        },
      ]
  
    return (
        <Layout>
            <SEO
                title="Kuhmichel Website"
                description="Q:marketing realisiert Website für Strahl- und Schleifmittelexperten."
                image={data?.ogimage?.childImageSharp?.fixed}
            />
                        
                        {/* posterImage={data.heropic.childImageSharp.gatsbyImageData} */}
            <BigHeaderVideo mode="textFirst" className={`${projekteStyles.child}`} bgVideo={myLocalVid} videoMode="local">                
                <div className="row py-5 py-md-0">
                    <div className="col-12 col-md-5">
                        <img src={logo} className="h-40px" alt=""/>
                        <h1>Kuhmichel.com</h1>
                        <p className="introtext">Website für den Experten für Strahl- und Schleifmittel.</p>
                        <AnchorLink to="/projekte/websites/kuhmichel/#thumbleiste" title="↓ Mehr Erfahren" className="q-btn q-btn-primary" />
                    </div>
                    <div className="col-7"></div>
                </div>
            </BigHeaderVideo>                                                               
                               
            {/* Bilderstrecke Thumbs */}   
            <ThumbleisteSwiper picdata={data.allFile} bgCol="#EFEFE6"/>    

            {/* Reasons why */}
            <ListenModule headline="Reasons why">
                <Liste
                    items={[                        
                        "Ziel war die Vereinheitlichung der Websites der einzelnen Ländergesellschaften zu einem globalen Auftritt mit Auswahlmöglichkeit der regionalen Angebote.",
                        "Neues Screendesign, das zugleich modern und zeitlos ist.",
                        "Von Smartphones über Tablets bis hin zur klassischen Desktopansicht ist die Seite für alle Endgeräte optimiert.",
                        "Der Einsatz eines CMS bietet Kuhmichel die Möglichkeit, Inhalte kostensparend und zeitnah selbst zu pflegen."
                    ]}
                />
            </ListenModule>
            
            <Specs data={specsData} />             
            
            {/* navigation */}            
            <Navigation location={props.location} />

            {/* kontakt */}            
            <Kontakt ansprechpartner="christian-frieling"/>

        </Layout>
    )
}

export default KuhmichelWebsite